import { Component, OnInit, Output, ViewChild, EventEmitter, Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ExcelData } from 'src/app/dashboard/dashboard.component';
import Swal from 'sweetalert2';
import { DataService } from 'src/app/_services/data.service';
import { Router } from '@angular/router';
import { MerchDashboardService } from '../../_services/merch-dashboard.service';
import { MerchFilterComponent } from '../merch-filter/merch-filter.component';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import * as moment from 'moment';
interface Navigator {
  msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
}
@Component({
  selector: 'app-dashboard-merch',
  templateUrl: './dashboard-merch.component.html',
  styleUrls: ['./dashboard-merch.component.scss']
})
export class DashboardMerchComponent implements OnInit {

  loadingprice: boolean = false
  skus: any
  auditImageData = [];
  auditImageDataLoaded = false;
  currentCursor = null;
  limit = 10; // Number of items to fetch per page
  nextCursor: string | null = null;
  previousCursor: string | null = null; // Store the next cursor

  // availability
  availabilityBarChartDataLoaded: boolean;
  public availabilityBarChartData: ChartDataSets[] = [];
  public availabilityBarChartLabels: Label[] = [];
  availabilityBarChartExcelData: ExcelData = { labels: [], data: [] };
  availabilityArray = [];
  modal: any = false;

  // oos
  outOfStockBarChartDataLoaded: boolean;
  public outOfStockBarChartData: ChartDataSets[] = [];
  public outOfStockBarChartLabels: Label[] = [];
  outOfStockBarChartExcelData: ExcelData = { labels: [], data: [] };
  outOfStockArray = [];

  // self share
  selfShareBarChartDataLoaded: boolean;
  public selfShareBarChartData: ChartDataSets[] = [];
  public selfShareBarChartLabels: Label[] = [];
  selfShareBarChartExcelData: ExcelData = { labels: [], data: [] };
  selfShareArray = [];

  // self share
  selfBalanceBarChartDataLoaded: boolean;
  public selfBalanceBarChartData: ChartDataSets[] = [];
  public selfBalanceBarChartLabels: Label[] = [];
  selfBalanceBarChartExcelData: ExcelData = { labels: [], data: [] };
  selfBalanceArray = [];

  // secondary display
  secondaryDisplayBarChartDataLoaded: boolean;
  public secondaryDisplayBarChartData: ChartDataSets[] = [];
  public secondaryDisplayBarChartLabels: Label[] = [];
  secondaryDisplayBarChartExcelData: ExcelData = { labels: [], data: [] };
  secondaryDisplayArray = [];


  // secondary display
  storeBalanceBarChartDataLoaded: boolean;
  public storeBalanceBarChartData: ChartDataSets[] = [];
  public storeBalanceBarChartLabels: Label[] = [];
  storeBalanceBarChartExcelData: ExcelData = { labels: [], data: [] };
  storeBalanceArray = [];


  // Price monitor
  priceMaxDataArray = [];
  priceMinDataArray = [];
  priceMeanDataArray = [];
  priceModeDataArray = [];
  priceMinMaxBarChartExcelData: ExcelData = { labels: [], data: [] };
  public priceMinMaxBarChartData: ChartDataSets[] = [];
  public priceMinMaxBarChartLabels: Label[] = [];


  // Promotion
  promotionGiftDataArray = [];
  promotionBuyOneDataArray = [];
  promotionDiscountDataArray = [];
  promotionBarChartExcelData: ExcelData = { labels: [], data: [] };
  public promotionBarChartData: ChartDataSets[] = [];
  public promotionBarChartLabels: Label[] = [];

  @ViewChildren('target1,target2,target3,target4,target5,target6,target7') targets: QueryList<ElementRef>;

  currentFilterString: string;
  filterarary: any
  // common variables
  height = '100';

  constructor(
    private merchOperationService: MerchOperationService,
    private dashboardService: MerchDashboardService,
    private dataService: DataService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loadMerchChartApis();
    setTimeout(() => {
      console.log(this.availabilityBarChartData)
    }, 10000);
  }
  filterApplied(filterString) {

    if (filterString) {
      this.currentFilterString = filterString;
    }
    this.refreshMerchChartApis(filterString);
    this.availabilityBarChartDataLoaded = false;
  }
  // main methods
  loadMerchChartApis(filterString?) {

    this.filterarary = this.getarray(filterString)

    const date_after = moment().startOf('month').format('YYYY-MM-DD');
    const date_before = moment().format('YYYY-MM-DD');
    this.filterarary.date_after = date_after;
    this.filterarary.date_before = date_before;
    this.filterarary.brand_ids = [70, 71, 77, 78, 79, 80, 83, 84]
    console.log(this.filterarary)
    this.availabilityReport(this.filterarary);
    this.outOfStockReport(this.filterarary);
    this.selfShareReport(this.filterarary);
    // this.selfBalanceReport(this.filterarary);
    this.secondaryDisplayReport(this.filterarary);
    // this.storeBalanceReport(this.filterarary);
    this.priceMinMaxReport(this.filterarary);
    this.promotionReport(this.filterarary);
    this.auditImageReport(this.filterarary);
  }
  refreshMerchChartApis(filterString?) {
    this.filterarary = this.getarray(filterString)

    console.log(this.filterarary)
    this.nextCursor = null;
    this.previousCursor = null;
    this.currentCursor = null;
    this.availabilityReport(this.filterarary);
    this.outOfStockReport(this.filterarary);
    this.selfShareReport(this.filterarary);
    // this.selfBalanceReport(this.filterarary);
    this.secondaryDisplayReport(this.filterarary);
    // this.storeBalanceReport(this.filterarary);
    this.priceMinMaxReport(this.filterarary);
    this.promotionReport(this.filterarary);
    this.auditImageReport(this.filterarary);
  }

  resetFilter() {
    this.nextCursor = null
    this.previousCursor = null
    this.currentCursor = null
    this.refreshMerchChartApis();
  }

  getarray(queryString: string): any {
    const params = new URLSearchParams(queryString);
    const jsonParams: any = {};

    // Map each parameter to its corresponding key in the JSON object
    params.forEach((value, key) => {
      // Convert comma-separated values to arrays
      jsonParams[key] = value.split(',').map(item => isNaN(Number(item)) ? item : Number(item));
    });

    // add _ids in end of all keys except date_before and date_after
    const jsonParamsWithIds = {};
    Object.keys(jsonParams).forEach(key => {
      if (key !== 'date_before' && key !== 'date_after') {
        // Ensure the value is always an array
        const values = Array.isArray(jsonParams[key]) ? jsonParams[key] : [jsonParams[key]];

        if (values[0] !== 0) {
          jsonParamsWithIds[key + '_ids'] = values;

        }
      } else {
        jsonParamsWithIds[key] = jsonParams[key];
      }
    });

    // Convert string "0" to numeric 0
    if (jsonParamsWithIds['date_before'] === '0') {
      jsonParamsWithIds['date_before'] = 0;
    }

    if (jsonParamsWithIds['date_after'] === '0') {
      jsonParamsWithIds['date_after'] = 0;
    }

    //dearray date_before and date_after
    if (jsonParamsWithIds['date_before']) {
      jsonParamsWithIds['date_before'] = jsonParamsWithIds['date_before'][0];
    }

    if (jsonParamsWithIds['date_after']) {
      jsonParamsWithIds['date_after'] = jsonParamsWithIds['date_after'][0];
    }


    return jsonParamsWithIds;
  }



  availabilityReport(filterArray?) {
    Swal.showLoading();

    this.dashboardService.availabilityReport(filterArray)
      .subscribe(
        response => {
          this.availabilityBarChartDataLoaded = true;
          this.availabilityBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const availabilityData = response.data ? [...response.data] : [];
            this.availabilityArray = availabilityData.map(eachSKUData => Math.round(eachSKUData.value));
            this.availabilityBarChartLabels = availabilityData.map(eachSKUData => eachSKUData.brand);

            this.availabilityBarChartData = [
              { data: this.availabilityArray, label: 'Available' }
            ];

            this.availabilityBarChartExcelData.labels = Object.keys(availabilityData[0]);
            this.availabilityBarChartExcelData.data = availabilityData;

          } else {
            this.availabilityBarChartData = [{ data: [], label: '' }];
            this.availabilityBarChartLabels = [];
            this.availabilityBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.availabilityBarChartDataLoaded = true;
          Swal.close();
          this.availabilityBarChartData = [{ data: [], label: '' }];
          this.availabilityBarChartLabels = [];
          this.availabilityBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching availability data!', true);
        }
      );
  }
  outOfStockReport(filterArray?) {
    Swal.showLoading();
    this.dashboardService.outOfStockReport(filterArray)
      .subscribe(
        response => {
          this.outOfStockBarChartDataLoaded = true;
          this.outOfStockBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const outOfStockData = response.data ? [...response.data] : [];
            this.outOfStockArray = outOfStockData.map(eachSKUData => Math.round(eachSKUData.value));
            this.outOfStockBarChartLabels = outOfStockData.map(eachSKUData => eachSKUData.brand);
            this.outOfStockBarChartData = [
              { data: this.outOfStockArray, label: 'OOS' }
            ];

            this.outOfStockBarChartExcelData.labels = Object.keys(outOfStockData[0]);
            this.outOfStockBarChartExcelData.data = outOfStockData;


          } else {
            this.outOfStockBarChartData = [{ data: [], label: '' }];
            this.outOfStockBarChartLabels = [];
            this.outOfStockBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.outOfStockBarChartDataLoaded = true;
          Swal.close();
          this.outOfStockBarChartData = [{ data: [], label: '' }];
          this.outOfStockBarChartLabels = [];
          this.outOfStockBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching oos data!', true);
        }
      );
  }

  selfShareReport(filterArray?) {
    Swal.showLoading();
    this.dashboardService.selfShareReport(filterArray)
      .subscribe(
        response => {
          this.selfShareBarChartDataLoaded = true;
          this.selfShareBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const selfShareData = response.data ? [...response.data] : [];
            this.selfShareArray = selfShareData.map(eachData => Math.round(eachData.value));
            this.selfShareBarChartLabels = selfShareData.map(eachData => eachData.brand);
            this.selfShareBarChartData = [
              { data: this.selfShareArray, label: 'Shelf Share' }
            ];

            this.selfShareBarChartExcelData.labels = Object.keys(selfShareData[0]);
            this.selfShareBarChartExcelData.data = selfShareData;

          } else {
            this.selfShareBarChartData = [{ data: [], label: '' }];
            this.selfShareBarChartLabels = [];
            this.selfShareBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.selfShareBarChartDataLoaded = true;
          Swal.close();
          this.selfShareBarChartData = [{ data: [], label: '' }];
          this.selfShareBarChartLabels = [];
          this.selfShareBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching shelf share data!', true);
        }
      );
  }
  forceDownload(link, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", link, true);

    // No caching
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Pragma', 'no-cache');

    // Expect a Blob (binary data)
    xhr.responseType = "blob";

    xhr.onload = function () {
      if (xhr.status === 200) {
        // Create a download link
        var url = window.URL.createObjectURL(xhr.response); // Use xhr.response directly
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        // Append anchor to the body (necessary for the click to trigger in some browsers)
        document.body.appendChild(a);
        a.click(); // Programmatically trigger the download
        document.body.removeChild(a); // Clean up the anchor

        // Revoke the object URL to free memory
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Image download failed with status: " + xhr.status);
      }
    };

    // Handle errors
    xhr.onerror = function () {
      console.error("Request failed");
    };

    // Send the request
    xhr.send();
  }


  scrollToItem(targetIndex: number): void {
    const target = this.targets.toArray()[targetIndex - 1];
    console.log(target,this.targets, targetIndex - 1)
    if (target) {
      target.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  selfBalanceReport(filterArray?) {
    Swal.showLoading();
    this.dashboardService.selfBalanceReport(filterArray)
      .subscribe(
        response => {
          this.selfBalanceBarChartDataLoaded = true;
          this.selfBalanceBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const selfShareData = response.data ? [...response.data] : [];
            this.selfBalanceArray = selfShareData.map(eachData => Math.round(eachData.value));
            this.selfBalanceBarChartLabels = selfShareData.map(eachData => eachData.brand);
            this.selfBalanceBarChartData = [
              { data: this.selfBalanceArray, label: 'Shelf Balance' }
            ];

            this.selfBalanceBarChartExcelData.labels = Object.keys(selfShareData[0]);
            this.selfBalanceBarChartExcelData.data = selfShareData;

          } else {
            this.selfBalanceBarChartData = [{ data: [], label: '' }];
            this.selfBalanceBarChartLabels = [];
            this.selfBalanceBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.selfBalanceBarChartDataLoaded = true;
          Swal.close();
          this.selfBalanceBarChartData = [{ data: [], label: '' }];
          this.selfBalanceBarChartLabels = [];
          this.selfBalanceBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching shelf balance data!', true);
        }
      );
  }

  openDialog(image, category, branch, store) {
    Swal.fire({
      title: `${category} - ${branch} - ${store}`,
      imageUrl: image,
      imageWidth: 500,
      imageHeight: 500,
      showConfirmButton: false,
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: 'Download',
      denyButtonColor: "#afcb3a",
      preDeny: () => {
        this.forceDownload(image, store)
      }
    });
  }

  secondaryDisplayReport(filterArray?) {
    Swal.showLoading();
    this.dashboardService.secondaryDisplayReport(filterArray)
      .subscribe(
        response => {
          this.secondaryDisplayBarChartDataLoaded = true;
          this.secondaryDisplayBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const secondaryDisplayData = response.data ? [...response.data] : [];
            this.secondaryDisplayArray = secondaryDisplayData.map(eachData => eachData.value);
            this.secondaryDisplayBarChartLabels = secondaryDisplayData.map(eachData => eachData.sku);
            this.secondaryDisplayBarChartData = [
              { data: this.secondaryDisplayArray, label: 'Secondary Balance' }
            ];

            this.secondaryDisplayBarChartExcelData.labels = Object.keys(secondaryDisplayData[0]);
            this.secondaryDisplayBarChartExcelData.data = secondaryDisplayData;

          } else {
            this.secondaryDisplayBarChartData = [{ data: [], label: '' }];
            this.secondaryDisplayBarChartLabels = [];
            this.secondaryDisplayBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.secondaryDisplayBarChartDataLoaded = true;
          Swal.close();
          this.secondaryDisplayBarChartData = [{ data: [], label: '' }];
          this.secondaryDisplayBarChartLabels = [];
          this.secondaryDisplayBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching secondary display data!', true);
        }
      );
  }

  storeBalanceReport(filterArray?) {
    Swal.showLoading();
    this.dashboardService.storeBalanceReport(filterArray)
      .subscribe(
        response => {
          this.storeBalanceBarChartDataLoaded = true;
          this.storeBalanceBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success && response.data.length > 0) {
            const storeBalanceData = response.data ? [...response.data] : [];
            this.storeBalanceArray = storeBalanceData.map(eachData => eachData.value);
            this.storeBalanceBarChartLabels = storeBalanceData.map(eachData => eachData.sku);
            this.storeBalanceBarChartData = [
              { data: this.storeBalanceArray, label: 'Store Balance' }
            ];

            this.storeBalanceBarChartExcelData.labels = Object.keys(storeBalanceData[0]);
            this.storeBalanceBarChartExcelData.data = storeBalanceData;

          } else {
            this.storeBalanceBarChartData = [{ data: [], label: '' }];
            this.storeBalanceBarChartLabels = [];
            this.storeBalanceBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          this.storeBalanceBarChartDataLoaded = true;
          Swal.close();
          this.storeBalanceBarChartData = [{ data: [], label: '' }];
          this.storeBalanceBarChartLabels = [];
          this.storeBalanceBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching store balance data!', true);
        }
      );
  }

  async getSkuList(Ids: any[], subcategory_ids: any[]): Promise<any[]> {
    try {
      const res = await this.merchOperationService.getBasicSku({ brand_ids: Ids, subcategory_ids }).toPromise();
      Swal.close();
      if (res && res.success) {
        this.skus = res.data;
      } else {
        this.skus = [];
      }
      return this.skus;
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: error.error_message || 'Encountered error while fetching sku!',
        timer: 1500
      });
      return [];
    }
  }

  async priceMinMaxReport(filterArray?) {
    Swal.showLoading();
    if (!filterArray.brand_ids) {
      return
    } else {
      this.loadingprice = true
      let sku_ids: any = []
      if (!filterArray.sku_ids && filterArray.brand_ids && this.filterarary.subcategory_ids) {
        let skus
        try {
          skus = await this.merchOperationService.getBasicSku({ brand_ids: filterArray.brand_ids, subcategory_ids: this.filterarary.subcategory_ids }).toPromise();
          Swal.close();
          if (skus && skus.success) {
            skus = skus.data;
          } else {
            this.skus = [];
          }
        } catch (error) {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Encountered error while fetching sku!',
            timer: 1500
          });
          skus = [];
        }

        skus.map(i => {
          sku_ids.push(i.id)
        })

        sku_ids.length = 8
      } else {
        sku_ids = filterArray.sku_ids

      }

      console.log(filterArray)
      delete filterArray.branchno_ids
      console.log(sku_ids)
      // Modify the filter string to only include date_after, date_before and add sku_ids

      this.dashboardService.priceMinMaxReport({ ...filterArray, sku_ids })
        .subscribe(
          response => {
            this.priceMinMaxBarChartExcelData = { labels: [], data: [] };
            Swal.close();
            this.loadingprice = false
            if (response && response.success) {
              const priceMinMaxData = response.data ? [...response.data] : [];

              // this.priceMaxDataArray = priceMinMaxData.map(eachSKUData => eachSKUData.max);
              // this.priceMinDataArray = priceMinMaxData.map(eachSKUData => eachSKUData.min);
              this.priceModeDataArray = priceMinMaxData.map(eachSKUData => eachSKUData.mode);
              this.priceMeanDataArray = priceMinMaxData.map(eachSKUData => eachSKUData.mean);

              this.priceMinMaxBarChartLabels = priceMinMaxData.map(eachSKUData => eachSKUData.sku);
              this.priceMinMaxBarChartData = [
                // { data: this.priceMaxDataArray, label: 'max' },
                // { data: this.priceMinDataArray, label: 'min' },
                { data: this.priceMeanDataArray, label: 'mean' },
                { data: this.priceModeDataArray, label: 'mode' },
              ];

              this.priceMinMaxBarChartExcelData.labels = Object.keys(priceMinMaxData[0]);
              this.priceMinMaxBarChartExcelData.data = priceMinMaxData;

            } else {
              this.priceMinMaxBarChartData = [{ data: [], label: '' }];
              this.priceMinMaxBarChartLabels = [];
              this.priceMinMaxBarChartExcelData = { labels: [], data: [] };

            }
          },
          error => {
            Swal.close();
            this.priceMinMaxBarChartData = [{ data: [], label: '' }];
            this.priceMinMaxBarChartLabels = [];
            this.priceMinMaxBarChartExcelData = { labels: [], data: [] };
            this.handleNotification('Error fetching mean mode data!', true);
          }
        );
    }
  }

  promotionReport(filterArray?) {

    Swal.showLoading();
    this.dashboardService.promotionReport(filterArray)
      .subscribe(
        response => {
          this.promotionBarChartExcelData = { labels: [], data: [] };
          Swal.close();
          if (response && response.success) {
            const promotionData = response.data ? [...response.data] : [];

            this.promotionGiftDataArray = promotionData.map(eachSKUData => eachSKUData.gift);
            this.promotionBuyOneDataArray = promotionData.map(eachSKUData => eachSKUData.buy_one);
            this.promotionDiscountDataArray = promotionData.map(eachSKUData => eachSKUData.discount);

            this.promotionBarChartLabels = promotionData.map(eachSKUData => eachSKUData.sku);
            this.promotionBarChartData = [
              { data: this.promotionGiftDataArray, label: 'gift' },
              { data: this.promotionBuyOneDataArray, label: 'buy one' },
              { data: this.promotionDiscountDataArray, label: 'discount' },
            ];

            this.promotionBarChartExcelData.labels = Object.keys(promotionData[0]);
            this.promotionBarChartExcelData.data = promotionData;

          } else {
            this.promotionBarChartData = [{ data: [], label: '' }];
            this.promotionBarChartLabels = [];
            this.promotionBarChartExcelData = { labels: [], data: [] };

          }
        },
        error => {
          Swal.close();
          this.promotionBarChartData = [{ data: [], label: '' }];
          this.promotionBarChartLabels = [];
          this.promotionBarChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error fetching promotion data!', true);
        }
      );
  }

  loadNext() {
    if (this.nextCursor !== null) {
      this.currentCursor = this.nextCursor;
      this.auditImageReport(this.filterarary);
    }
  }

  loadPrevious() {
    if (this.previousCursor !== null) {
      this.currentCursor = this.previousCursor;
      this.auditImageReport(this.filterarary);
    }
  }

  auditImageReport(filterArray?) {
    Swal.showLoading()
    console.log(filterArray, this.currentCursor)
    this.dashboardService.auditImageReport(filterArray, this.currentCursor, 10)
      .subscribe(
        response => {
          this.auditImageDataLoaded = true;
          Swal.close();
          if (response && response.success && response.data.results.length > 0) {
            this.auditImageData = response.data.results ? [...response.data.results] : [];
            this.nextCursor = response.data.next; // Capture the next cursor
            this.previousCursor = response.data.previous; // Capture the next cursor
            console.log(this.auditImageData);
          } else {
            this.auditImageData = [];
          }
        },
        error => {
          this.auditImageDataLoaded = true;
          Swal.close();
          this.auditImageData = [];
          this.handleNotification('Error fetching audit image data!', true);
        }
      );
  }

  // format data
  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }
}

